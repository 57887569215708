import { type Field } from 'o365-dataobject';

declare module "o365.pwa.declaration.sw.DataObjectFieldUtilities.d.ts" {
    export enum SortDirection {
        Desc = 'desc',
        Asc = 'asc'
    }

    export enum GroupByAggregates {
        Avg = "AVG",
        Count = "COUNT",
        Count_Distinct = "COUNT_DISTINCT",
        Sum = "SUM",
        Max = "MAX",
        Min = "MIN"
    }

    export interface ISortColumns {
        name: string;
        sortOrder: number;
        sortDirection: SortDirection;
    }

    export interface IGroupByColumns {
        name: string;
        groupByOrder: number;
    }

    export interface IGroupByAggregates {
        name: string;
        groupByAggregate: string;
        alias: string;
    }

    export class DataObjectFieldUtilities {
        static getSortColumns(fields: Array<Field>): Array<ISortColumns>;
        static getGroupByAggregates(fields: Array<Field>): Array<IGroupByAggregates>;
        static getGroupByColumns(fields: Array<Field>): Array<IGroupByColumns>;
        static getSortingArray(sortColumns: Array<ISortColumns>): Array<any>;
    }

}
